import Dropdown from "bootstrap/js/dist/dropdown";
import Collapse from "bootstrap/js/dist/collapse";

$(function () {
  
  $(window).on("load", function () {
    $(".signup").fadeIn("fast");
  });
  $("#navicon").click(function () {
    $(this).toggleClass("is-active");
  });
  
  $(".dropdown-item").click(function () {
    $(this).parents(".dropdown").children("button.dropdown-toggle").text($(this).data("value"));
    $(this).parents(".dropdown").children("input").val($(this).data("value"));
    const x = $("#ageForm").serializeArray();
    console.log(x);
  });
  
  const submitButton = $(".prep_ui_submit button.std_reg_btn");
  const consentCheckbox = $("input.consent_element.primary_consent");
  const emailInput = $("input.prep_ui_inp[type=\"email\"]");
  if (submitButton.length > 0) {
    validateEmail();
  }
  emailInput.keyup(function () {
    validateEmail();
  });
  $("#verifyResident").click(function () {
    if ($(this).is(":checked")) {
      $("#showContestButton").prop("disabled", false).removeClass("disabled");
    } else {
      $("#showContestButton").prop("disabled", true).addClass("disabled");
      $("#contest").addClass("d-none");
    }
  });
  
  $("#showContestButton").click(function () {
    $("#verify").addClass("d-none");
    $("#contest").removeClass("d-none");
    $(".prep_ui_formelm_consent").css("float", "none");
  });
  
  consentCheckbox.click(function () {
    validateEmail();
  });
  
  submitButton.click(function () {
    console.log("clicked submit");
  });
  
  let emailStatus = "invalid";
  
  function validateEmail() {
    let inputValue = emailInput.val();
    var testEmail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/;
    if (!testEmail.test(inputValue)) {
      submitButton.prop("disabled", true);
      emailStatus = "invalid";
    } else if (consentCheckbox.is(":checked")) {
      submitButton.prop("disabled", false);
      emailStatus = "valid";
    }
  }
});

